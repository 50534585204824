import React from 'react';
import Logo from 'images/logo.svg';
import { Container, Navbar } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const Header: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Navbar expand="lg" className="bg-body-tertiary">
      <Container fluid>
        <Navbar.Brand href="/">
          <div className="logo">
            <img src={Logo} alt="logo" />
            <div className="separator"></div>
            <h1>{t('header.title')}</h1>
          </div>
        </Navbar.Brand>
      </Container>
    </Navbar>
  );
};

export default Header;
