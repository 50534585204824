import React from 'react';
import styled from 'styled-components';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

interface StrategyTitleProps {
  id: string;
  discarded?: boolean;
}
const StyledRow = styled(Row)`
  & > * {
    flex: 0 0 auto;
    width: auto;
  }
  .strategyId {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 2.8125rem;
    border: 0.187rem solid var(--rak-palette-atmosphere);
    line-height: 1.25rem;

    &.discarded {
      border-color: rgb(255, 153, 0);
      color: rgb(255, 153, 0);
    }
  }
  .discarded {
    color: rgb(255, 153, 0);
  }
`;

const StrategyTitle: React.FC<StrategyTitleProps> = ({
  id,
  discarded,
}: StrategyTitleProps): React.ReactElement => {
  const { t } = useTranslation();
  return (
    <StyledRow className="justify-content-start align-items-center mb-2 mt-2 blue-text-rak">
      <Col
        className={`text-uppercase pe-2 blue-text-rak ${
          discarded ?? false ? 'discarded' : ''
        }`}
      >
        {t('quote.details.strategy_quote.title')}
      </Col>
      <Col
        className={` strategyId text-center ${
          discarded ?? false ? 'discarded' : ''
        }`}
      >
        {id}
      </Col>
    </StyledRow>
  );
};

export default StrategyTitle;
