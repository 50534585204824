/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-unused-vars */
import type L from 'leaflet';
import { type LatLngExpression } from 'leaflet';
import React, { useContext, useEffect, useState } from 'react';
import { Polyline, useMap } from 'react-leaflet';

import 'leaflet/dist/leaflet.css';
import styled from 'styled-components';
import { useDeviceType } from 'shared/useDeviceType';
import { type StrategyContextType } from 'types';
import StrategyContext from 'context/StrategyContext';

interface CustomPolylineProps {
  positions: LatLngExpression[] | LatLngExpression[][];
  children: React.ReactNode;
  isActive: boolean;
  tension_nbr?: number;
  bounds: Array<[number, number]>;
  uid: string;
  point?: any;
  strategyType?: string;
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface CustomPolylineStyleCSSProps {
  power: number;
  active: boolean;
}

const CustomPolylineStyle = styled(Polyline)`
  /*
  filter: ${(props: CustomPolylineStyleCSSProps) => {
    if (props.active) {
      return 'drop-shadow(-1px -1px 0px #04248e) drop-shadow(2px -1px 0px #04248e) drop-shadow(0px 0px 0px #04248e) drop-shadow(-1px 1px 0px #04248e)';
    } else {
      return 'unset';
    }
  }};
  */
`;
const addOrRemoveNumber = (uids: string[], uid: string): string[] => {
  if (uids.includes(uid)) {
    uids.splice(uids.indexOf(uid), 1);
  } else {
    uids.push(uid);
  }

  return uids;
};
const getTracetColor = (power: number | undefined): string => {
  if (power != null) {
    if (power >= 6300 && power <= 9000) {
      return '#8f00ff';
    } else if (power >= 15000 && power <= 22500) {
      return '#0000ff';
    }
  }

  return '#09A0E1';
};
const CustomPolyline: React.FC<CustomPolylineProps> = ({
  isActive,
  positions,
  tension_nbr,
  children,
  bounds,
  uid,
}) => {
  const polylineRef = React.useRef<L.Polyline | null>(null);
  const { isDesktop } = useDeviceType();
  const [polylineWeight, setPolylineWeight] = useState(12);
  const { strategyContext, setStrategyContext } =
    useContext<StrategyContextType>(StrategyContext);
  const { selectedStrategies } = strategyContext;
  const map = useMap();
  useEffect(() => {
    map.invalidateSize();
    map.fitBounds(bounds);
    // making active paths the uppermost layers so that they are not covered by inactive ones.
    if (isActive) polylineRef.current?.bringToFront();
  }, [isActive]);
  return (
    <Polyline
      pathOptions={{
        opacity: 1,
        weight: polylineWeight,
        stroke: true,
        color: isActive ? getTracetColor(tension_nbr) : '#929497',
        className: 'shadow-box',
      }}
      positions={positions}
      eventHandlers={{
        mouseover: (e: L.LeafletMouseEvent) => {
          setPolylineWeight(20);
          e.target.openPopup();
        },
        mouseout: (e: L.LeafletMouseEvent) => {
          setPolylineWeight(12);
          e.target.closePopup();
        },
        click: (e: L.LeafletMouseEvent) => {
          setStrategyContext({
            ...strategyContext,
            selectedStrategies: addOrRemoveNumber(selectedStrategies, uid),
          });
        },
      }}
      weight={polylineWeight}
      ref={polylineRef}
    >
      {children}
      {/* {isDesktop && (
        <Popup closeButton={false}>
          <PointPopup
            lat={lat}
            lng={lng}
            point={point}
            strategyType={strategyType}
          />
        </Popup>
      )} */}
    </Polyline>
  );
};

export default CustomPolyline;
