/* eslint-disable @typescript-eslint/no-floating-promises */
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

// Import all translation files
import translationFr from './fr/translation.json';

// ---Using translation
const resources = {
  fr: {
    translation: translationFr,
  },
};

i18next.use(initReactI18next).init({
  resources,
  lng: 'fr', // default language
});

export default i18next;
