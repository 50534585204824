/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import styled from 'styled-components';
import { type FiltersContextType } from 'types';
import FiltersContext from 'context/FiltersContext';
import { type Coordinates } from 'models/Coordinates';
import { Icon } from 'shared/Icon';
import { API_ADDRESS_URL } from 'utils/constants';
import ClickOutside from './ClickOutside';
import { showErrorNotification } from 'services/NotificationService';
import { useTranslation } from 'react-i18next';

const StyledDiv = styled.div`
  width: 100%;
  position: relative;
  display: inline-block;
  .search-input {
    width: 100%;
    padding: 11px;
    font-size: 13px;
    color: var(--rak-palette-textSearch);
    &.invalid {
      border: 1px solid var(--rak-palette-bd_red);
    }
  }

  .autocomplete-items {
    position: absolute;
    border: 1px solid #d4d4d4;
    background: #fff;
    border-bottom: none;
    border-top: none;
    padding-left: 0;
    z-index: 9999;
    top: 100%;
    left: 0;
    right: 0;

    li {
      list-style: none;
      padding-left: 0;
      a {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 15px;
        color: var(--rak-palette-textSearch);
        cursor: pointer;
        display: block;
        padding: 0.625rem 0.3125rem 0.625rem 1.25rem;

        &:hover {
          background: rgba(115, 171, 255, 0.2);
        }
      }
    }
  }

  .input-group {
    flex-wrap: nowrap;
    width: 96%;

    .reset-icon {
      position: absolute;
      top: 50%;
      right: 12%;
      cursor: pointer;
      transform: translate(-50%, -50%);
    }
  }

  .input-group-text {
    padding: 0;

    .btn-rak-primary {
      height: 1.952rem;
      width: 2rem;

      .search-icon {
        cursor: pointer;
        position: relative;
        bottom: 0.2rem;
      }
    }
  }

  .search-input {
    width: 100%;
    height: 2rem;
    padding-left: 0.75rem;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 0.8125rem;
    line-height: 0.6875rem;
    color: var(--rak-palette-textSearch);
  }
  .text-truncate {
    padding-right: 1.4rem;
  }
`;

const AutocompleteInput: React.FC = () => {
  const { t } = useTranslation();

  const refInput = useRef<HTMLInputElement>(null);

  const { filtersContext, setFiltersContext } =
    useContext<FiltersContextType>(FiltersContext);

  const { coordinates } = filtersContext;

  const defaultPlaceholder = t('strategy.search.placeholder');
  const [addresses, setAddresses] = useState<Coordinates[]>([]);
  const [resetInput, setResetInput] = useState<boolean>(false);
  const [isValid, setIsValid] = useState<boolean>(false);

  const [selectedIndex, setSelectedIndex] = useState(-1);

  const isValidAddress = (address: string): boolean => {
    // Regex pattern for a French address: [Number] [Street Name], [Optional Complement], [Postal Code] [City]
    // const regex =
    //   /^[0-9]{1,3}\s[A-Za-zÀ-ÖØ-öø-ÿ\s]{1,100},?\s?[0-9]{5}\s[A-Za-zÀ-ÖØ-öø-ÿ\s]{1,100}$/;
    const regex =
      /[0-9]*[ |[a-zà-ú.,-]* ((highway)|(autoroute)|(north)|(nord)|(south)|(sud)|(east)|(est)|(west)|(ouest)|(avenue)|(lane)|(voie)|(ruelle)|(road)|(rue)|(route)|(drive)|(boulevard)|(circle)|(cercle)|(street)|(cer\.?)|(cir\.?)|(blvd\.?)|(hway\.?)|(st\.?)|(aut\.?)|(ave\.?)|(ln\.?)|(rd\.?)|(hw\.?)|(dr\.?)|(a\.))([ .,-]*[a-zà-ú0-9]*)*/i;
    // const addressPattern =
    //   /^(\d+)?\s*([a-zA-ZÀ-ÿ\s\-']+)\s*(\d+)?\s*([a-zA-ZÀ-ÿ\s\-']+)?\s*(\d{5})\s*([a-zA-ZÀ-ÿ\s\-']+)$/u;
    return regex.test(address);
  };

  const onChangeAddress = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    const term = event.target.value;
    setFiltersContext({
      ...filtersContext,
      coordinates: {
        label: term ?? '',
        lng: coordinates?.lng ?? 0,
        lat: coordinates?.lat ?? 0,
      },
    });

    if (term.length > 5) {
      getListAddressByTerm(term);
    } else if (term.length === 0) {
      setRestMap();
    }
  };

  const handleSelected = (address: Coordinates): void => {
    setFiltersContext({
      ...filtersContext,
      coordinates: address,
    });
    setShowElement(false);
  };

  const getListAddressByTerm = (term: string): void => {
    fetch(`${API_ADDRESS_URL}?q=${term.trim()}&autocomplete=5&limit=10`)
      .then(async (response) => await response.json())
      .then((data) => {
        const results: Coordinates[] = data.features.map((feature: any) => {
          return {
            label: feature.properties.label,
            lng: feature.geometry.coordinates[0],
            lat: feature.geometry.coordinates[1],
          };
        });
        setShowElement(true);
        setResetInput(true);
        setAddresses(results);
        return results;
      })
      .catch((error) => {
        console.log(error);
        showErrorNotification(t('quote.list.errors.500'));
        setIsValid(true);
      });
  };

  const getAddressByEnter = (term: string): void => {
    fetch(
      `${API_ADDRESS_URL}?q=${term.trim()}&type=housenumber&autocomplete=5&limit=5`,
    )
      .then(async (response) => await response.json())
      .then((result) => {
        const feature = result.features[0];
        const currentAddress = {
          label: feature.properties.label,
          lng: feature.geometry.coordinates[0],
          lat: feature.geometry.coordinates[1],
        };

        handleSelected(currentAddress);
        return currentAddress;
      })
      .catch((error) => {
        console.log(error);
        showErrorNotification(t('quote.list.errors.500'));
        setIsValid(true);
      });
  };

  const onClickAddress = (): void => {
    if (
      coordinates?.label !== undefined &&
      coordinates?.label.length > 5 &&
      isValidAddress(coordinates?.label)
    ) {
      handleSelected(addresses[0]);
    } else if (
      coordinates?.label !== undefined &&
      coordinates?.label.length === 0
    ) {
      setRestMap();
    }
  };

  const onChangeSearch = (): void => {
    if (coordinates?.label !== undefined && coordinates?.label.length === 0) {
      setRestMap();
    }
    setShowElement(true);
  };

  const setRestMap = (): void => {
    setAddresses([]);
    setFiltersContext({
      ...filtersContext,
      coordinates: { label: '', lng: 0, lat: 0 },
    });

    setResetInput(false);
  };

  const onResetSearch = (): void => {
    setRestMap();
  };

  // Show/Hide list to Click Outside Element
  const [showElement, setShowElement] = useState(true);
  const handleClickOutside = (): void => {
    setShowElement(false);
  };

  useEffect(() => {
    if (coordinates?.label !== undefined && coordinates?.label.length === 0) {
      setRestMap();
    }

    setFiltersContext({
      ...filtersContext,
      coordinates: {
        label: coordinates?.label ?? '',
        lng: coordinates?.lng ?? 0,
        lat: coordinates?.lat ?? 0,
      },
    });
  }, [coordinates?.label]);

  // key Press handler function
  const handlekeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      event.key === 'Enter' &&
      coordinates?.label !== undefined &&
      coordinates?.label.length > 5 &&
      isValidAddress(coordinates?.label)
    ) {
      getAddressByEnter(coordinates?.label);
    }
  };

  // navigate list with keyboard
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    switch (event.key) {
      case 'ArrowUp':
        event.preventDefault();
        setSelectedIndex((prevIndex) =>
          prevIndex <= 0 ? addresses.length - 1 : prevIndex - 1,
        );
        break;
      case 'ArrowDown':
        event.preventDefault();
        setSelectedIndex((prevIndex) =>
          prevIndex === addresses.length - 1 ? 0 : prevIndex + 1,
        );
        break;
      case 'Enter':
        if (selectedIndex !== -1) {
          event.preventDefault();
          handleSelected(addresses[selectedIndex]);
          setSelectedIndex(-1);
        }
        break;
      case 'Escape':
        setFiltersContext({
          ...filtersContext,
          coordinates: {
            label: coordinates?.label ?? '',
            lng: coordinates?.lng ?? 0,
            lat: coordinates?.lat ?? 0,
          },
        });
        setSelectedIndex(-1);
        break;
    }
  };

  const handleBlur = () => {
    setTimeout(() => {
      setSelectedIndex(-1);
    }, 100);
  };

  return (
    <StyledDiv className="autocomplete">
      <InputGroup className={`input-group mx-2`}>
        <Form.Control
          placeholder={
            coordinates?.label !== undefined && coordinates?.label.length > 0
              ? coordinates?.label
              : defaultPlaceholder
          }
          className={`search text-truncate ${isValid ? 'is-invalid' : ''}`}
          bsPrefix="form-control-rak"
          ref={refInput}
          onInput={onChangeAddress}
          onKeyPress={handlekeyPress}
          onKeyDown={handleKeyDown}
          onBlur={handleBlur}
          onClick={onChangeSearch}
          value={coordinates?.label}
          title={
            coordinates?.label !== undefined && coordinates?.label.length > 5
              ? coordinates?.label.toString()
              : ''
          }
        />
        {resetInput && (
          <Icon
            iconName="XLg"
            color="black"
            className="reset-icon"
            onClick={onResetSearch}
          />
        )}
        <InputGroup.Text id="inputGroup-sizing-sm">
          <Button
            className=""
            variant="primary"
            bsPrefix="btn-rak"
            onClick={onClickAddress}
          >
            <Icon iconName="Search" color="white" className="search-icon" />
          </Button>
        </InputGroup.Text>
      </InputGroup>

      {addresses.length > 0 && showElement && (
        <ClickOutside onClickOutside={handleClickOutside}>
          <ul className="autocomplete-items">
            {addresses.map((address, index) => (
              <li key={index}>
                <a
                  onClick={() => {
                    handleSelected(address);
                  }}
                  onMouseEnter={() => {
                    setSelectedIndex(index);
                  }}
                  style={
                    index === selectedIndex
                      ? { backgroundColor: 'rgba(115, 171, 255, 0.2)' }
                      : {}
                  }
                >
                  {address.label}
                </a>
              </li>
            ))}
          </ul>
        </ClickOutside>
      )}
    </StyledDiv>
  );
};
export default AutocompleteInput;
