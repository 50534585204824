/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { type IStrategy } from 'models/Strategy';
import StrategyContent from './strategy-content';
import { type StrategyContextType } from 'types';
import StrategyContext from 'context/StrategyContext';

interface StrategyItemProps {
  strategy: IStrategy;
}

export const StrategyItem: React.FC<StrategyItemProps> = ({ strategy }) => {
  const { strategyContext } = useContext<StrategyContextType>(StrategyContext);
  const { selectedStrategies } = strategyContext;
  const isActive = selectedStrategies.includes(strategy.uid);
  return (
    <Row className={`item-strategy ${!isActive ? 'pb-2' : 'active pb-2'}`}>
      <Col className="col-md-12 col-sm-12 col-12">
        <StrategyContent strategy={strategy} />
      </Col>
    </Row>
  );
};

export default StrategyItem;
