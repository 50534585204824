import React from 'react';
import styled from 'styled-components';

const StyledDiv = styled.div`
  .loading {
    position: absolute;
    /* margin: 0px auto; */
    /* display: flex; */
    top: 50%;
    left: 50%;
    font-size: 23px;
    z-index: 9999;
    transform: translate(-50%, -50%);
  }

  .backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    width: 100vw;
    height: 100vh;
    background-color: rgb(255, 255, 255, 0.5);
  }

  .lds-dual-ring {
    display: inline-block;
    width: 80px;
    height: 80px;
  }
  .lds-dual-ring:after {
    content: ' ';
    display: block;
    width: 3rem;
    height: 3rem;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid #000;
    border-color: #000 transparent #000 transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }
  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .message {
    position: absolute;
    top: 60%;
    font-size: 1rem;
  }
`;

interface LoadingProps {
  message?: string;
}

export const Loading: React.FC<LoadingProps> = ({ message }) => {
  return (
    <StyledDiv>
      <div className="backdrop"></div>
      <div className="loading">
        <div className="lds-dual-ring" role="status">
          {message !== '' && <span className="message">{message}</span>}
        </div>
      </div>
    </StyledDiv>
  );
};
