import { type IFiltersContext } from 'models/Context';
import { type IStrategyContext } from 'models/StrategyContext';
import React, { useEffect } from 'react';
import { useMap } from 'react-leaflet';
import CustomMarker from './CustomMarker';
import CustomPolygon from './CustomPolygon';
interface MapChildProps {
  filtersContext: IFiltersContext;
  strategyContext: IStrategyContext;
}

export const MapChild: React.FC<MapChildProps> = ({
  filtersContext,
  strategyContext,
}: MapChildProps): React.ReactElement => {
  const map = useMap();
  const bounds: Array<[number, number]> = strategyContext.data.map(
    ({ pointCoords: { lat, lng } }) => {
      return [lat, lng];
    },
  );

  useEffect(() => {
    map.invalidateSize();
    map.fitBounds(bounds);
  }, [filtersContext, strategyContext]);

  return (
    <>
      <CustomMarker
        lng={Number(filtersContext.coordinates?.lng)}
        lat={Number(filtersContext.coordinates?.lat)}
        label={filtersContext.coordinates?.label}
        position={true}
      />
      <CustomPolygon bounds={bounds} />
    </>
  );
};
