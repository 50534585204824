/* eslint-disable @typescript-eslint/explicit-function-return-type */
// NotificationService.ts

import { toast, type ToastOptions } from 'react-toastify';

const defaultToastOptions: ToastOptions = {
  position: 'top-right',
  autoClose: 10000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
};

export const showSuccessNotification = (message: string) => {
  toast.success(message, defaultToastOptions);
};

export const showInfoNotification = (message: string) => {
  toast.info(message, defaultToastOptions);
};

export const showWarningNotification = (message: string) => {
  toast.warning(message, defaultToastOptions);
};

export const showErrorNotification = (message: string) => {
  toast.error(message, defaultToastOptions);
};
