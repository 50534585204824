/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext } from 'react';
import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { type StrategyContextType, type FiltersContextType } from 'types';
import FiltersContext from 'context/FiltersContext';
import StrategyFooter from '../StrategyFooter';
import StrategyContext from 'context/StrategyContext';
import StrategySearch from './StrategySearch';
import StrategyItem from '../strategy-item';

interface RowCSSProps {
  sent: boolean;
}

const StyledRow = styled(Row)`
  & > * {
  }
  .flex-item-1 {
    height: 2.875rem;
  }
  .flex-item-2 {
    height: calc(100% - 2.875rem - 3.75rem);
    overflow-y: scroll;
  }
  .flex-item-3 {
    height: 3rem;
  }
  height: calc(100vh - 7.4rem);
  display: ${(props: RowCSSProps) => {
    return props.sent ? 'flex' : 'none';
  }};
`;
const StrategyLayout: React.FC = (): JSX.Element => {
  const { t } = useTranslation();
  const { filtersContext, setFiltersContext } =
    useContext<FiltersContextType>(FiltersContext);
  const { coordinates, isSent } = filtersContext;
  const {
    strategyContext: { data },
    setStrategyContext,
  } = useContext<StrategyContextType>(StrategyContext);

  const { strategyContext } = useContext<StrategyContextType>(StrategyContext);
  const { selectedStrategies } = strategyContext;
  const onHideList = (): void => {
    setFiltersContext({
      ...filtersContext,
      openList: false,
    });
  };

  const labelFooter =
    selectedStrategies.length > 1 ? t('footer.labelList') : t('footer.label');

  return (
    <StyledRow sent={isSent ? 1 : 0} className="flex-row strategy-row">
      <Col className="col-sm-12 col-md-12 col-12 flex-item-1">
        <StrategySearch length={data.length} onHideList={onHideList} />
      </Col>
      <Col className="col-sm-12 col-md-12 col-12 flex-item-2">
        {data.map((strategy, index) => {
          return <StrategyItem key={strategy.id} strategy={strategy} />;
        })}
      </Col>
      <Col className="flex-item-3">
        <StrategyFooter
          label={labelFooter}
          itemsLength={selectedStrategies.length}
        />
      </Col>
    </StyledRow>
  );
};

export default StrategyLayout;
