/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import styled from 'styled-components';
import { type IInterval } from 'models/Strategy';
import { Col, Row } from 'react-bootstrap';

interface StrategyParametersProps {
  distance: IInterval;
  cost: IInterval;
}
interface IntervalItemProps {
  value: string;
  text: string;
}
interface RowItemProps {
  text: string;
  min: IntervalItemProps;
  max: IntervalItemProps;
  hasLeftBorder: boolean;
}

const StyledRowItem = styled(Row)`
  & > * {
    flex: 0 0 auto;
  }
  .separator-col {
    border-right: 1px solid #09a0e1;
    padding-right: 8px;
  }
  .unit {
    font-size: 0.6rem;
  }
  .icon {
    font-size: 0.4rem;
  }
`;
const RowItem: React.FC<RowItemProps> = ({
  text,
  min,
  max,
  hasLeftBorder,
}): React.ReactElement => {
  return (
    <StyledRowItem className="flex-nowrap">
      <Col>
        <Row className="flex-column">
          <Col>{text}</Col>
          <Col>
            <Row
              className={`${
                hasLeftBorder ? 'flex-column separator-col' : 'flex-column'
              }`}
            >
              <Col>
                <Row>
                  <Col className="fw-bolder">{min.value}</Col>
                  <Col className="d-flex align-items-center justify-content-center">
                    <span className="icon icon-equiv-arrow"></span>
                  </Col>
                  <Col className="fw-bolder">{max.value}</Col>
                </Row>
              </Col>
              <Col>
                <Row className="fst-italic unit">
                  <Col>{min.text}</Col>
                  <Col></Col>
                  <Col>{max.text}</Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </StyledRowItem>
  );
};
const StyledRow = styled(Row)`
  background-color: var(--rak-rgba-atmosphere);
  border-radius: 0.3125rem;
  padding: 0.375rem 0.6875rem;
  color: var(--rak-palette-body_color);
  --bs-gutter-x: 0;
`;
const StrategyParameters: React.FC<StrategyParametersProps> = ({
  distance,
  cost,
}): React.ReactElement => {
  return (
    <StyledRow style={{ columnGap: '12px' }}>
      <Col>
        <RowItem
          text="Distance (km)"
          min={{
            value: `${Math.round(distance.minValue / 1000)}`,
            text: 'Réelle',
          }}
          max={{
            value: `${Math.round(distance.maxValue / 1000)}`,
            text: 'Majorée',
          }}
          hasLeftBorder={true}
        />
      </Col>
      <Col>
        <RowItem
          text="Coût min (k€)"
          min={{
            value: Math.round(cost.minValue).toLocaleString('fr-FR'),
            text: 'Min',
          }}
          max={{
            value: Math.round(cost.maxValue).toLocaleString('fr-FR'),
            text: 'Max',
          }}
          hasLeftBorder={false}
        />
      </Col>
    </StyledRow>
  );
};

export default StrategyParameters;
