import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.scss';
import App from './App';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import PageNotFound from 'components/404/404';
import Strategy from 'components/strategy';
// Import i18n.ts
import './translation/i18n';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <PageNotFound />,
    children: [
      {
        path: '/',
        element: <Strategy />,
      },
      {
        path: '/404',
        element: <PageNotFound />,
      },
    ],
  },
]);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
);
