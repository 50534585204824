/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button, Col, Container, Form, InputGroup, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import ClientChips from './ClientChips';
import {
  type StrategyContextType,
  type FiltersContextType,
  type FiltersFormType,
} from 'types';
import FiltersContext from 'context/FiltersContext';
import AutocompleteInput from './AutocompleteInput';
import { postStrategies } from 'services/strategies';
import StrategyContext from 'context/StrategyContext';
import { useDeviceType } from 'shared/useDeviceType';
import CoordsInput from './CoordsInput';
import { showErrorNotification } from 'services/NotificationService';

const StyledContainer = styled(Container)`
  background: #f5f5f5;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
  .search-container {
    .btn-link {
      color: #009cdf;
      font-family: Open Sans;
      font-size: 14px;
      font-weight: 600;
      line-height: 16px;
      letter-spacing: 1px;
      text-align: left;
      text-decoration: none;
    }
    .search-item {
      position: relative;
      border-right: 1px solid #e0e0e0;
      @media (max-width: 992px) {
        border-right: none;
      }
      .error_message {
        font-size: 0.6875rem;
        font-weight: 300;
        color: var(--rak-palette-bd_red) !important;
      }
      .serach-input {
        width: 100%;
        padding: 0.625rem;
        font-size: 0.8125rem;
        color: var(--rak-palette-textSearch);
        &.invalid {
          border: 0.0625rem solid var(--rak-palette-bd_red);
        }
      }
    }
    .separator {
      width: 0px;
      border: 0.0625rem solid #e0e0e0;
    }
    .disabled {
      background-color: #e0e0e0;
      cursor: not-allowed;
      pointer-events: none;
      color: white;
    }
    .power {
      position: relative;
      display: inline-block;
      .power-input {
        width: 100%;
        padding: 0.625rem;
        font-size: 0.8125rem;
        color: var(--rak-palette-textSearch);
        &.invalid {
          border: 0.0625rem solid var(--rak-palette-bd_red);
        }
      }
      .power-icon {
        position: absolute;
        top: 18%;
        right: 25%;
        transform: translate(50%, 50%);
        font-size: 0.8125rem;
      }
      .form-control-rak {
        margin: 0.625rem;
        width: 8rem;
        position: relative;
        background-color: rgb(245, 245, 245);

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }
      .form-label {
        margin-bottom: 0;
        font-size: 8px;
        font-weight: 500;
        display: inline-block;
        position: absolute;
        top: 3px;
        left: 12px;
        padding: 2px;
        z-index: 1;

        &:after {
          content: ' ';
          background-color: rgb(245, 245, 245);
          width: 100%;
          height: 13px;
          position: absolute;
          left: 0;
          bottom: 0;
          z-index: -1;
        }
      }
    }
  }
  .refresh-search {
    border: none;
    padding: 0.45rem 0.8rem;
    color: rgba(0, 156, 223, 1);
    background: transparent;
    font-size: 0.8rem;
    font-weight: 600;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;

    .icon {
      font-size: 1.2rem;
    }
  }
  .form-switch {
    .form-check-input {
      width: 1.5rem;
      height: 1em;
    }
    .form-check-input:checked {
      background-position: right center;
      background-color: rgba(0, 156, 223, 1);
      border-color: rgba(0, 156, 223, 1);
    }
  }
`;

const Search: React.FC = () => {
  const { t } = useTranslation();

  const { filtersContext, setFiltersContext } =
    useContext<FiltersContextType>(FiltersContext);

  const { isMobile, isTablet } = useDeviceType();
  const isDesktop = !isMobile && !isTablet;

  const { strategyContext, setStrategyContext } =
    useContext<StrategyContextType>(StrategyContext);

  const { coordinates, power, clientType } = filtersContext;

  const onHideList = (): void => {
    setFiltersContext({
      isSent: false,
      openSearch: false,
      clientType,
      power,
      coordinates: {
        label: coordinates?.label ?? '',
        lng: coordinates?.lng ?? 0,
        lat: coordinates?.lat ?? 0,
      },
    });
    setStrategyContext({
      ...strategyContext,
      data: [],
      selectedStrategies: [],
      openStrategy: false,
      isLoading: false,
    });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const powerValue = Number(e.target.value);
    setFiltersContext({
      ...filtersContext,
      power: powerValue,
    });
  };
  const setFiltersForm = (filters: FiltersFormType): void => {
    setFiltersContext({ ...filtersContext, ...filters });
  };
  const handleSearch = (): void => {
    setStrategyContext({
      ...strategyContext,
      isLoading: true,
    });

    const filter = {
      clientType: filtersContext.clientType,
      power: filtersContext.power,
      coordinates: filtersContext.coordinates,
    };

    void postStrategies(filter)
      .then((response: any) => {
        if (response.data.length > 0) {
          setStrategyContext({
            ...strategyContext,
            data: response.data,
            isLoading: false,
          });
        } else {
          onHideList();
          showErrorNotification(t('strategy.search.errors.empty'));
        }
      })
      .catch((error) => {
        console.log('error', error);
        onHideList();
        showErrorNotification(t('strategy.search.errors.500'));
      });

    setFiltersContext({
      ...filtersContext,
      isSent: true,
      openSearch: false,
      openList: true,
    });
  };
  const ref = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (ref.current != null) {
      ref.current.focus();
    }
  }, []);

  const isValid = Boolean(
    filtersContext.power > 0 &&
      filtersContext.clientType.length > 0 &&
      filtersContext.coordinates?.label !== '',
  );

  const closeSearch = (): void => {
    setFiltersContext({
      ...filtersContext,
      openSearch: false,
    });
  };

  const refreshSearch = (): void => {
    // setFiltersContext({
    //   ...filtersContext,
    //   isSent: false,
    //   openSearch: false,
    //   clientType: '',
    //   power: 0,
    //   coordinates: {
    //     label: '',
    //     lng: 0,
    //     lat: 0,
    //   },
    // });
    // setStrategyContext({
    //   ...strategyContext,
    //   data: [],
    //   selectedStrategies: [],
    //   openStrategy: false,
    //   isLoading: false,
    // });
    window.location.reload();
  };

  // Show/Hide Switch Coords(lng,lat) / address
  const [showCoords, setShowCoords] = useState(false);

  const handleSwitchCoords = (): void => {
    setShowCoords(!showCoords);
  };

  return (
    <StyledContainer fluid>
      <Row className="search-container align-content-center align-items-center">
        <Col className="search-item search-mobile" lg={3} md={4} sm={6} xs={12}>
          <div className="d-flex align-items-center justify-content-between">
            {!showCoords ? <AutocompleteInput /> : <CoordsInput />}

            <Button
              className="my-2 me-1"
              variant="link"
              // bsPrefix="btn-rak"
              onClick={handleSwitchCoords}
              size="sm"
            >
              {!showCoords
                ? t('strategy.search.gps')
                : t('strategy.search.address')}
            </Button>
          </div>
        </Col>
        <Col className="search-item px-2" lg={4} md={5} sm={6} xs={6}>
          <ClientChips
            setFiltersForm={setFiltersForm}
            currentClient={filtersContext.clientType}
          />
        </Col>
        <Col lg={2} md={2} sm={4} xs={6}>
          <div className="power">
            <Form.Label htmlFor="basic-url">
              {t('strategy.search.power')}
            </Form.Label>
            <InputGroup>
              <Form.Control
                type="number"
                bsPrefix="form-control-rak"
                className={`power-input`}
                onChange={handleChange}
                min="0"
                // value={filtersContext.power}
                onWheel={(event) => {
                  event.preventDefault();
                }}
                placeholder="0"
              />
            </InputGroup>
            <span className={`power-icon`}>{`MW`}</span>
          </div>
        </Col>
        <Col
          lg={3}
          md={4}
          sm={8}
          className={`text-end d-flex  ${
            isDesktop ? 'justify-content-end' : 'justify-content-between'
          }`}
        >
          <Button
            className="my-2 mx-2 d-lg-none d-md-none clear-search"
            variant="outline-secondary"
            bsPrefix="btn-rak"
            onClick={closeSearch}
          >
            <i className="icon icon-arrow_up"></i>
          </Button>
          <Button
            className="my-2 mx-2 refresh-search"
            variant="outline-secondary"
            bsPrefix="btn-rak"
            onClick={refreshSearch}
          >
            <i className="icon icon-refresh me-1"></i>{' '}
            {t('strategy.search.refresh')}
          </Button>
          <Button
            className="my-2 mx-2 me-3"
            variant="primary"
            bsPrefix="btn-rak"
            onClick={handleSearch}
            disabled={!isValid}
          >
            {t('strategy.search.button')}
          </Button>
        </Col>
      </Row>
    </StyledContainer>
  );
};

export default Search;
