import React, { type ReactNode } from 'react';
// import { useRouteError } from 'react-router-dom';

interface PropsPageNotFound {
  children?: ReactNode;
}

const PageNotFound: React.FC<PropsPageNotFound> = ({ children }) => {
  return (
    <div className="d-flex align-items-center justify-content-center vh-100">
      <div className="text-center">
        <h1 className="display-1 fw-bold">Oops!</h1>
        <p className="lead text-danger fs-3">{`Il y'a une erreur.`}</p>
        {/* <p>{error !== null && <i>{error?.message}</i>}</p> */}
      </div>
    </div>
  );
};

export default PageNotFound;
