/* eslint-disable no-prototype-builtins */
import React from 'react';
import { type IPoint, type IInterval } from 'models/Strategy';

import { Alert, Badge, Col, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import StrategyParameters from './StrategyParameters';
import StrategyTitle from '../StrategyTitle';
import { useDeviceType } from 'shared/useDeviceType';
import { formattedPower } from 'utils';

interface StrategyDescriptionProps {
  point: IPoint;
  connectionMessage?: string;
  distance?: IInterval;
  cost?: IInterval;
  id: string;
  strategyType: string;
  isReady: boolean;
  tension?: number;
  clickHandler: () => void;
}

export const StyledRow = styled(Row)`
  .flex-item {
    height: 1.5rem;
  }
  .alert-warning {
    --bs-alert-color: rgba(255, 153, 0, 1);
    --bs-alert-bg: transparent;
    --bs-alert-border-color: rgba(255, 153, 0, 1);
    width: 105px;
    padding: 1px 5px;
    border-radius: 2px;
    color: #f90;
    font-family: Open Sans;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
  }
  .tension {
    font-family: 'Open Sans';
    font-size: 13px;
    font-weight: 700;
    line-height: 1;
    letter-spacing: 0px;
    text-align: center;
    color: rgb(9, 160, 225);
    border: 2px solid rgb(9, 160, 225);
    padding: 0.3rem;
    display: block;
    height: 1.6rem;
    margin-right: 0.4rem;
    margin-top: 0.3rem;
    border-radius: 3px;
  }
  .power {
    max-width: 250px;
  }
`;

const StrategyDescription: React.FC<StrategyDescriptionProps> = ({
  point,
  connectionMessage,
  strategyType,
  distance,
  cost,
  id,
  isReady,
  tension,
  clickHandler,
}): React.ReactElement => {
  const { t } = useTranslation();

  const { code, name, details } = point;
  const { isDesktop } = useDeviceType();
  return (
    <StyledRow onClick={clickHandler}>
      <Col lg={{ span: '7' }} md={{ span: '7' }} xs={{ span: '12' }}>
        <Row>
          <Col md={{ span: '12' }} className="flex-item mb-3">
            <StrategyTitle id={id} />
          </Col>
          {!isDesktop && (
            <Col
              md={{ span: '12' }}
              xs={{ span: '12' }}
              className="d-flex justify-content-between"
            >
              {tension !== undefined && (
                <span className="tension">{formattedPower(tension)}</span>
              )}
              <h6 className="mt-1">
                <Badge bg="primary">
                  {t([
                    `quote.details.strategy_quote.connector_type_labels.${strategyType}`,
                  ])}{' '}
                </Badge>
              </h6>
            </Col>
          )}
          <Col md={{ span: '12' }} className="flex-item">
            <b>
              {t([
                `quote.details.strategy_quote.strategy_type_labels.${strategyType}`,
              ])}{' '}
              <span title={code}>{code}</span>
            </b>
          </Col>
          <Col md={{ span: '12' }} className="flex-item text-truncate">
            <span title={name}>{name}</span>
          </Col>
          <Col md={{ span: '12' }} className="flex-item text-truncate">
            {t([
              `quote.details.strategy_quote.strategy_detail_labels.${strategyType}`,
            ])}{' '}
            <b title={details}>{details}</b>
          </Col>
          {!isReady && (
            <Col md={{ span: '12' }} className="flex-item text-truncate">
              <Alert variant={'warning'}>
                {t([
                  `quote.details.strategy_quote.strategy_type_labels.${strategyType}`,
                ])}{' '}
              </Alert>
            </Col>
          )}
          {!isDesktop && (
            <>
              {cost !== undefined && distance !== undefined && (
                <Col md={{ span: '12' }} xs={{ span: '12' }}>
                  <StrategyParameters cost={cost} distance={distance} />
                </Col>
              )}
            </>
          )}
        </Row>
      </Col>
      {isDesktop && (
        <Col
          lg={{ span: '5' }}
          md={{ span: '5' }}
          xs={{ span: '12' }}
          style={{ display: 'flex', justifyContent: 'end' }}
        >
          <Row className="power d-none d-md-block d-lg-block">
            <Col
              md={{ span: '12' }}
              xs={{ span: '12' }}
              className="d-flex justify-content-between"
            >
              {tension !== undefined && (
                <span className="tension">{formattedPower(tension)}</span>
              )}
              <h6 className="mt-1">
                <Badge bg="primary">
                  {t([
                    `quote.details.strategy_quote.connector_type_labels.${strategyType}`,
                  ])}{' '}
                </Badge>
              </h6>
            </Col>
            {cost !== undefined && distance !== undefined && (
              <Col md={{ span: '12' }} xs={{ span: '12' }}>
                <StrategyParameters cost={cost} distance={distance} />
              </Col>
            )}
          </Row>
        </Col>
      )}
    </StyledRow>
  );
};

export default StrategyDescription;
