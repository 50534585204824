/* eslint-disable @typescript-eslint/restrict-template-expressions */
// environnement

export const formattedDate = (date: Date): string => {
  return new Date(date).toLocaleDateString('fr-FR', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });
};

export const formattedDateEn = (date: Date): string => {
  return new Date(date)
    .toLocaleDateString('en-GB', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    })
    .split('/')
    .reverse()
    .join('-');
};

export const getCostValue = (value: number): string => {
  return `${Math.round(value).toLocaleString('fr-FR')} k€`;
};

export const getCostValueCommas = (value: number): string => {
  return value !== undefined
    ? `${value.toLocaleString('fr-FR', {
        maximumFractionDigits: 1,
      })} k€`
    : `0 k€`;
};

export const getdistance = (distance: number): string => {
  return distance < 1000
    ? `${Math.round(distance).toString().replace('.', ',')} m`
    : `${(Math.round(distance) / 1000).toString().replace('.', ',')} km`;
};

export const formattedPower = (tension: number): string => {
  return `${Math.round(tension / 1000).toString()}KV`;
};
