/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { useContext, useEffect } from 'react';
import StrategyLayout from 'components/strategy/strategy-layout';
import { Button, Col, Collapse } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { useDeviceType } from 'shared/useDeviceType';
import { StrategyDetails } from './StrategyDetails';
import FiltersContext from 'context/FiltersContext';
import { type StrategyContextType, type FiltersContextType } from 'types';
import StrategyContext from 'context/StrategyContext';
import Map from '../strategy/map';

const Strategy: React.FC = (): React.ReactElement => {
  const { t } = useTranslation();

  const { filtersContext, setFiltersContext } =
    useContext<FiltersContextType>(FiltersContext);

  const { strategyContext, setStrategyContext } =
    useContext<StrategyContextType>(StrategyContext);
  const {
    openStrategy,
    openMap,
    openStrategyDetails,
    selectedStrategies,
    selectedStrategy,
  } = strategyContext;

  const { isMobile, isTablet } = useDeviceType();
  const isDesktop = !isMobile && !isTablet;

  useEffect(() => {
    // Check is Desktop: to display Carte & Strategy
    if (isDesktop) {
      setStrategyContext({
        ...strategyContext,
        openMap: true,
        openStrategy: true,
      });
    }
  }, [strategyContext.openMap, strategyContext.openStrategy]);

  const handleOpenSearch = (): void => {
    setFiltersContext({
      ...filtersContext,
      openSearch: true,
    });
  };

  const handleOpenMap = (): void => {
    setStrategyContext({
      ...strategyContext,
      openMap: true,
      openStrategy: false,
      openStrategyDetails: false,
    });
  };

  const handleOpenStrategy = (): void => {
    setStrategyContext({
      ...strategyContext,
      openMap: true,
      openStrategy: true,
      openStrategyDetails: false,
    });
  };

  const isSelectedMobile =
    openStrategyDetails === true && selectedStrategy !== null
      ? 'selected-mobile'
      : '';
  const isSelectedStrategy =
    openStrategyDetails === true && selectedStrategies.length > 0
      ? 'selected-strategy'
      : '';

  return (
    <>
      {filtersContext.openSearch !== undefined &&
        !filtersContext.openSearch && (
          <Button
            onClick={() => {
              handleOpenSearch();
            }}
            aria-controls="collapse-search"
            aria-expanded={filtersContext.openSearch}
            variant="secondary"
            size="sm"
            className={`btn-display-search d-lg-none`}
          >
            {t('strategy.content.search')}{' '}
            <i className="icon icon-arrow_down search-icon"></i>
          </Button>
        )}

      {filtersContext.isSent &&
        strategyContext?.openMap !== undefined &&
        strategyContext?.openStrategy !== undefined && (
          <>
            {!strategyContext?.openStrategy ? (
              <Button
                onClick={() => {
                  handleOpenStrategy();
                }}
                aria-controls="collapse-carte"
                aria-expanded={openMap}
                variant="secondary"
                size="sm"
                className={`btn-display-strategy btn-list d-lg-none ${isSelectedMobile} ${isSelectedStrategy}`}
              >
                <i className="icon icon-list_bulleted mx-1"></i>
                {t('strategy.content.display_list')}
              </Button>
            ) : (
              <Button
                onClick={() => {
                  handleOpenMap();
                }}
                aria-controls="collapse-strategy"
                aria-expanded={openStrategy}
                variant="secondary"
                size="sm"
                className={`btn-display-strategy d-lg-none ${isSelectedMobile} ${isSelectedStrategy}`}
              >
                <i className="icon icon-close mx-1"></i>
                {t('strategy.content.close_map')}
              </Button>
            )}
          </>
        )}

      {filtersContext.isSent &&
        !strategyContext.isLoading &&
        openStrategy !== undefined &&
        openStrategy && (
          <Col xxl="5" lg="6" md="12" sm="12" className="strategy">
            <Collapse in={openStrategy}>
              <div id="collapse-strategy">
                {filtersContext.isSent && filtersContext.openList === true && (
                  <StrategyLayout />
                )}
              </div>
            </Collapse>
          </Col>
        )}

      {filtersContext.isSent &&
        selectedStrategy != null &&
        openStrategyDetails !== undefined &&
        openStrategyDetails && (
          <Col
            md="12"
            className={`strategy-mobile ${
              selectedStrategies.length > 0 ? 'selected' : ''
            }`}
          >
            <StrategyDetails id={selectedStrategy} />
          </Col>
        )}

      {!strategyContext.isLoading ? (
        <Col
          className={`map ${
            !filtersContext.isSent || filtersContext.openList === false
              ? 'col-12'
              : 'col-xxl-7 col-lg-6 col-md-12 col-sm-12'
          }`}
        >
          <Map
            filtersContext={filtersContext}
            strategyContext={strategyContext}
          />
        </Col>
      ) : (
        <Col className={`map col-12`}>
          <Map
            filtersContext={filtersContext}
            strategyContext={strategyContext}
          />
        </Col>
      )}
    </>
  );
};

export default Strategy;
