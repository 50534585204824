/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import axios from 'axios';
import { type IFilters } from 'models/Filters';
// This function is temporary, to simulate that the backend may take eome time to respond
const wait = async (ms: number): Promise<number> => {
  return await new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
};
const strategies = {
  data: [
    {
      id: 1,
      uid: '-EV4LzbOmppPQj3khFHqSw',
      ranking_distance: 0,
      type: 'AIRLINE',
      strategyType: 'LINE',
      tension: '90kV',
      tension_nbr: 90000,
      point: {
        code: 'DAMBRL41ZB.PR',
        name: 'LIT 90kV N0 1 DAMBRON - PIQUAGE A BOIS PRINCE (LE)',
        details: '56 de la LIT 90kV N0 1 DAMBRON - PIQUAGE A BOIS PRINCE (LE)',
      },
      distance: {
        minValue: 2654.505044400138,
        maxValue: 3185.4060532801655,
      },
      cost: {
        minValue: 1489054.4455283072,
        maxValue: 2301213.679253998,
      },
      isReady: true,
      polylineCoords: [
        [48.2066541, 1.8640015],
        [48.18612925039284, 1.8457662182755177],
      ],
      pointCoords: {
        lat: 48.18612925039284,
        lng: 1.8457662182755177,
      },
    },
    {
      id: 2,
      uid: 'cfmOouuEyVTwuj27uXpGhw',
      ranking_distance: 1,
      type: 'AIRLINE',
      strategyType: 'LINE',
      tension: '400kV',
      tension_nbr: 400000,
      point: {
        code: 'DAMBRL72VLEJU',
        name: 'LIT 400kV N0 2 DAMBRON - VILLEJUST',
        details: 'JA121 de la LIT 400kV N0 1 DAMBRON-YVELINES-OUEST',
      },
      distance: {
        minValue: 3446.6131110679253,
        maxValue: 4135.93573328151,
      },
      cost: {
        minValue: 3275077.2918981025,
        maxValue: 9847742.308126805,
      },
      isReady: true,
      polylineCoords: [
        [48.2066541, 1.8640015],
        [48.1901165314107, 1.9032148627686856],
      ],
      pointCoords: {
        lat: 48.1901165314107,
        lng: 1.9032148627686856,
      },
    },
    {
      id: 3,
      uid: 'yAb17HNaR2z5fE9P8qOgOA',
      ranking_distance: 2,
      type: 'AIRLINE',
      strategyType: 'LINE',
      tension: '225kV',
      tension_nbr: 225000,
      point: {
        code: 'DAMBRL62ZCAR6',
        name: 'LIT 225kV N0 2 DAMBRON - PIQUAGE LES CARRES A MARCOUSSIS (ZCAR6)',
        details:
          '253 de la LIT 225kV N0 2 DAMBRON - PIQUAGE LES CARRES A MARCOUSSIS (ZCAR6)',
      },
      distance: {
        minValue: 3487.1197228166516,
        maxValue: 4184.543667379981,
      },
      cost: {
        minValue: 2429738.567784736,
        maxValue: 5443580.531046067,
      },
      isReady: true,
      polylineCoords: [
        [48.2066541, 1.8640015],
        [48.191983410365495, 1.9054614279547983],
      ],
      pointCoords: {
        lat: 48.191983410365495,
        lng: 1.9054614279547983,
      },
    },
    {
      id: 4,
      uid: 'QPSmoRNkOq3BP5EhCwtFaQ',
      ranking_distance: 3,
      type: 'AIRLINE',
      strategyType: 'LINE',
      tension: '225kV',
      tension_nbr: 225000,
      point: {
        code: 'DAMBRL62ZESPI',
        name: 'LIT 225kV N0 2 DAMBRON-ESPI5 / CXNAY DAMBR 2',
        details: 'A482 de la LIT 225kV N0 2 DAMBRON-GAULT-SAINT-DENIS',
      },
      distance: {
        minValue: 3496.993554440391,
        maxValue: 4196.392265328469,
      },
      cost: {
        minValue: 2436617.7864007624,
        maxValue: 5458993.767506284,
      },
      isReady: true,
      polylineCoords: [
        [48.2066541, 1.8640015],
        [48.19356027207558, 1.8212298143395305],
      ],
      pointCoords: {
        lat: 48.19356027207558,
        lng: 1.8212298143395305,
      },
    },
    {
      id: 5,
      uid: 'elWSm2iK7noSmmQ4vEXqfQ',
      ranking_distance: 4,
      type: 'AIRLINE',
      strategyType: 'LINE',
      tension: '225kV',
      tension_nbr: 225000,
      point: {
        code: 'ZCARRL61ZSEMO',
        name: 'LIT 225kV N0 1 PIQUAGE LES CARRES A MARCOUSSIS (ZCARR) - PIQUAGE A SEMONVILLE',
        details: '253 de la LIT 225kV N0 1 PIQUAGE LES CARRES A MARCOUSSIS (ZC',
      },
      distance: {
        minValue: 3512.7747581666813,
        maxValue: 4215.329709800018,
      },
      cost: {
        minValue: 2447614.249726202,
        maxValue: 5483629.471635579,
      },
      isReady: true,
      polylineCoords: [
        [48.2066541, 1.8640015],
        [48.19189376486409, 1.9057808833138161],
      ],
      pointCoords: {
        lat: 48.19189376486409,
        lng: 1.9057808833138161,
      },
    },
    {
      id: 6,
      uid: '9ToFn7p6widfIHltF0TMaw',
      ranking_distance: 5,
      type: 'AIRLINE',
      strategyType: 'LINE',
      tension: '225kV',
      tension_nbr: 225000,
      point: {
        code: 'DAMBRL61ZJUST',
        name: 'LIT 225kV N0 1 DAMBRON - PIQUAGE A LA JUSTICE',
        details: '82 de la LIT 225kV N0 1 DAMBRON-GAULT-SAINT-DENIS',
      },
      distance: {
        minValue: 3531.7146185544207,
        maxValue: 4238.057542265305,
      },
      cost: {
        minValue: 2460810.5350524774,
        maxValue: 5513195.771291351,
      },
      isReady: true,
      polylineCoords: [
        [48.2066541, 1.8640015],
        [48.193464790400775, 1.8207815703311205],
      ],
      pointCoords: {
        lat: 48.193464790400775,
        lng: 1.8207815703311205,
      },
    },
    {
      id: 7,
      uid: 'YEkiBDRj39XjiqMM5fH6eg',
      ranking_distance: 6,
      type: 'AIRLINE',
      strategyType: 'LINE',
      tension: '225kV',
      tension_nbr: 225000,
      point: {
        code: 'DAMBRL61G.DEN',
        name: 'LIT 225kV N0 1 DAMBRON-GAULT-SAINT-DENIS',
        details: '83 de la LIT 225kV N0 1 DAMBRON-GAULT-SAINT-DENIS',
      },
      distance: {
        minValue: 3564.1940825300762,
        maxValue: 4277.032899036091,
      },
      cost: {
        minValue: 2483441.6878706026,
        maxValue: 5563896.956528998,
      },
      isReady: true,
      polylineCoords: [
        [48.2066541, 1.8640015],
        [48.1978859072163, 1.817881427769233],
      ],
      pointCoords: {
        lat: 48.1978859072163,
        lng: 1.817881427769233,
      },
    },
    {
      id: 8,
      uid: 'myQ8nbCQh94iY6ygXBe1Kw',
      ranking_distance: 7,
      type: 'AIRLINE',
      strategyType: 'LINE',
      tension: '225kV',
      tension_nbr: 225000,
      point: {
        code: 'DAMBRL62G.DEN',
        name: 'LIT 225kV N0 2 DAMBRON-GAULT-SAINT-DENIS',
        details: 'A484 de la LIT 225kV N0 2 DAMBRON-GAULT-SAINT-DENIS',
      },
      distance: {
        minValue: 3607.944025399182,
        maxValue: 4329.532830479018,
      },
      cost: {
        minValue: 2513924.9607865317,
        maxValue: 5632193.05832919,
      },
      isReady: true,
      polylineCoords: [
        [48.2066541, 1.8640015],
        [48.20145493521526, 1.816089523637348],
      ],
      pointCoords: {
        lat: 48.20145493521526,
        lng: 1.816089523637348,
      },
    },
    {
      id: 9,
      uid: 'UyC0M-0RiG3M0ccPPLrtbQ',
      ranking_distance: 8,
      type: 'AIRLINE',
      strategyType: 'LINE',
      tension: '400kV',
      tension_nbr: 400000,
      point: {
        code: 'DAMBRL71YVE.O',
        name: 'LIT 400kV N0 1 DAMBRON-YVELINES-OUEST',
        details: 'JA122 de la LIT 400kV N0 1 DAMBRON-YVELINES-OUEST',
      },
      distance: {
        minValue: 3616.886628964825,
        maxValue: 4340.26395475779,
      },
      cost: {
        minValue: 3436875.9543918087,
        maxValue: 10334252.364018304,
      },
      isReady: true,
      polylineCoords: [
        [48.2066541, 1.8640015],
        [48.185002900618855, 1.9003096269883373],
      ],
      pointCoords: {
        lat: 48.185002900618855,
        lng: 1.9003096269883373,
      },
    },
    {
      id: 10,
      uid: 'J73Cxl5fFJyNlezM4GwYfg',
      ranking_distance: 9,
      type: 'AIRLINE',
      strategyType: 'LINE',
      tension: '225kV',
      tension_nbr: 225000,
      point: {
        code: 'DAMBRL61ZSEMO',
        name: 'LIT 225kV N0 1 DAMBRON - PIQUAGE A SEMONVILLE',
        details: '262 de la LIT 225kV N0 1 DAMBRON - PIQUAGE A SEMONVILLE',
      },
      distance: {
        minValue: 4318.194468424,
        maxValue: 5181.8333621087995,
      },
      cost: {
        minValue: 3008805.1150265066,
        maxValue: 6740927.588311058,
      },
      isReady: true,
      polylineCoords: [
        [48.2066541, 1.8640015],
        [48.17377431209441, 1.8949073619231218],
      ],
      pointCoords: {
        lat: 48.17377431209441,
        lng: 1.8949073619231218,
      },
    },
  ],
};
export const getStrategies = async (): Promise<any> => {
  return await axios.get(`${process.env.REACT_APP_DEV}/v1/strategies`, {
    params: {
      power: 1200,
      clientType: 'Producteur',
      lng: 1.89143,
      lat: 48.16642,
    },
  });
};

export const postStrategies = async (filters: IFilters): Promise<any> => {
  const result = await axios.post(
    `${process.env.REACT_APP_DEV}/v1/strategies`,
    filters,
  );
  return result;
  // return await Promise.resolve(strategies);
};
