import { ClientTypeLabels } from 'enums';
import React from 'react';
import { Button, Col, Container, Form, InputGroup, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { type FiltersFormType } from 'types';
const StyledContainer = styled(Container)`
  .client-chips {
    column-gap: 10px;
  }

  .chips {
    position: relative;
    display: inline-block;
    .form-control-rak {
      margin: 0.625rem;
      width: 8rem;
      position: relative;
      background-color: rgb(245, 245, 245);

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
    .form-label {
      margin-bottom: 0;
      font-size: 8px;
      font-weight: 500;
      display: inline-block;
      position: absolute;
      top: -7px;
      left: 12px;
      padding: 2px;
      z-index: 1;

      &:after {
        content: ' ';
        background-color: rgb(245, 245, 245);
        width: 100%;
        height: 13px;
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: -1;
      }
    }
  }
`;
interface ClientChipsProps {
  setFiltersForm: (response: FiltersFormType) => void;
  currentClient: string;
}
const ClientChips: React.FC<ClientChipsProps> = (props: ClientChipsProps) => {
  const { t } = useTranslation();

  const { setFiltersForm, currentClient } = props;
  const handleChecked = (clientType: string): void => {
    if (currentClient === clientType) {
      setFiltersForm({ clientType: '', power: 0, isSent: false });
    } else {
      setFiltersForm({ clientType });
    }
  };

  const handleSelect = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    const clientType = e.target.value;
    console.log('clientType', clientType);
    if (currentClient === clientType) {
      setFiltersForm({ clientType: '', power: 0, isSent: false });
    } else {
      setFiltersForm({ clientType });
    }
  };

  return (
    <StyledContainer className="padding-10">
      <div className="d-none d-md-block d-lg-block">
        <Row className="d-inline-flex flex-nowrap client-chips">
          {Object.keys(ClientTypeLabels).map((elt) => {
            return (
              <Col key={elt}>
                <Button
                  className={`text-nowrap ${
                    currentClient === elt && currentClient !== ''
                      ? 'active'
                      : ''
                  }`}
                  variant="secondary"
                  bsPrefix="btn-rak"
                  onClick={() => {
                    handleChecked(elt);
                  }}
                >
                  {t([`client_type_labels.${elt}`])}
                </Button>
              </Col>
            );
          })}
        </Row>
      </div>
      <div className="d-block d-sm-none">
        <Row className="d-inline-flex flex-nowrap client-chips">
          <Col>
            <div className="chips">
              <Form.Label>{t('strategy.search.client')}</Form.Label>
              <InputGroup>
                <Form.Select onChange={handleSelect}>
                  <option value={''}>...</option>
                  {Object.keys(ClientTypeLabels).map((elt) => {
                    return (
                      <option
                        key={elt}
                        value={elt}
                        selected={currentClient === elt && currentClient !== ''}
                      >
                        {t([`client_type_labels.${elt}`])}
                      </option>
                    );
                  })}
                </Form.Select>
              </InputGroup>
            </div>
          </Col>
        </Row>
      </div>
    </StyledContainer>
  );
};

export default ClientChips;
