import StrategyContext from 'context/StrategyContext';
import { type IStrategy } from 'models/Strategy';
import React, { useContext } from 'react';
import { Col, Form, Row } from 'react-bootstrap';

import { type StrategyContextType } from 'types';
import StrategyDescription from './StrategyDescription';
import styled from 'styled-components';

interface StrategyContentProps {
  strategy: IStrategy;
}
const addOrRemoveNumber = (uids: string[], uid: string): string[] => {
  if (uids.includes(uid)) {
    uids.splice(uids.indexOf(uid), 1);
  } else {
    uids.push(uid);
  }

  return uids;
};
const isChecked = (uids: string[], uid: string): boolean => {
  return uids.includes(uid);
};
const StyledRow = styled(Row)`
  cursor: pointer;

  @media (max-width: 575.98px) {
    padding-bottom: 0.5rem;
  }

  & > * {
    flex: 0 0 auto;
  }
  .checkbox-col-rak {
    align-self: center;
    flex-basis: 2.1875rem;

    @media (max-width: 575.98px) {
      align-self: baseline;
      position: relative;
      top: 1rem;
    }
  }
  .description-col-rak {
    width: calc(100% - 2.1875rem);

    @media (max-width: 992px) {
      width: calc(40% - 2.1875rem);
    }

    @media (max-width: 769px) {
      width: calc(32% - 2.1875rem);
    }

    @media (max-width: 575.98px) {
      width: calc(90% - 2.1875rem);
      font-size: 0.725rem;
    }
  }
  .parameters {
    @media (max-width: 992px) {
      padding-top: 1.5rem;
    }

    @media (max-width: 575.98px) {
      padding-top: 0.2rem;
    }
  }
`;
const StrategyContent: React.FC<StrategyContentProps> = ({ strategy }) => {
  const { strategyContext, setStrategyContext } =
    useContext<StrategyContextType>(StrategyContext);
  const { selectedStrategies } = strategyContext;

  const onSelectStretegy = (uid: string, isActive: boolean): void => {
    setStrategyContext({
      ...strategyContext,
      selectedStrategies: addOrRemoveNumber(selectedStrategies, uid),
    });
  };
  return (
    <StyledRow>
      <Col className="checkbox-col-rak">
        <Form.Check
          checked={isChecked(selectedStrategies, strategy.uid)}
          as="input"
          value={strategy.id}
          bsSwitchPrefix="form-check-input-rak"
          onChange={() => {
            onSelectStretegy(
              strategy.uid,
              isChecked(selectedStrategies, strategy.uid),
            );
          }}
          type="checkbox"
        />
      </Col>
      <Col className="description-col-rak">
        <StrategyDescription
          point={strategy.point}
          connectionMessage={strategy.connectionMessage}
          strategyType={strategy.type}
          distance={strategy.distance}
          cost={strategy.cost}
          id={strategy.id}
          isReady={strategy.isReady}
          tension={strategy.tension_nbr}
          clickHandler={() => {
            onSelectStretegy(
              strategy.uid,
              isChecked(selectedStrategies, strategy.uid),
            );
          }}
        />
      </Col>
    </StyledRow>
  );
};

export default StrategyContent;
