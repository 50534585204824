/* eslint-disable @typescript-eslint/no-confusing-void-expression */
import React, { useContext } from 'react';
import { Document, Packer, Table, TableCell, TableRow, WidthType } from 'docx';
import StrategyContext from 'context/StrategyContext';

import { Button, Col, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { type FiltersContextType, type StrategyContextType } from 'types';
import {
  borders,
  createBreak,
  createHeading,
  createInfoRequest,
  createTitleText,
  footerDoc,
  getCostValue,
  getdistance,
  headerDoc,
} from './strategy-layout/generate-docx';
import FiltersContext from 'context/FiltersContext';
import { ClientTypeLabels } from 'enums';
import { type IStrategy } from 'models/Strategy';
interface StrategyFooterProps {
  itemsLength: number;
  label: string;
}
const StyledRow = styled(Row)`
  &.footer {
    border-top: 3px solid var(--rak-palette-atmosphere);
    .flex-item-2 {
      padding: 0.5rem;
      overflow-y: hidden;
    }
  }
`;
const StrategyFooter: React.FC<StrategyFooterProps> = ({
  label,
  itemsLength,
}) => {
  const { t } = useTranslation();
  const { strategyContext } = useContext<StrategyContextType>(StrategyContext);
  const { filtersContext } = useContext<FiltersContextType>(FiltersContext);
  const { selectedStrategies } = strategyContext;

  console.log('strategyContext', strategyContext, filtersContext);

  const isCoordinate = (long: number, lat: number): boolean => {
    const latMin = 42.3404785156;
    const latMax = 51.0971191406;

    const longMin = -4.7625;
    const longMax = 8.14033203125;

    const longOk = long >= longMin && long <= longMax;
    const latOk = lat >= latMin && lat <= latMax;

    return longOk && latOk;
  };

  // const handleAddressByCoords = async (
  //   lng: number,
  //   lat: number,
  // ): Promise<string> => {
  //   const response = await fetch(
  //     `${API_ADDRESS_URL_REVERSE}?lon=${lng}&lat=${lat}`,
  //   );
  //   const stringResponse = await response.text();
  //   console.log('ffff', stringResponse);
  //   return stringResponse;

  //   // return await fetch(`${API_ADDRESS_URL_REVERSE}?lon=${lng}&lat=${lat}`)
  //   //   .then(async (response) => await response.json())
  //   //   .then((result) => {
  //   //     if (result.features.length > 0) {
  //   //       const feature = result.features[0];

  //   //       return feature.properties.label;
  //   //     } else {
  //   //       const feature = `<b className="fw-bold">Lat:</b> ${lat
  //   //         .toString()
  //   //         .replace(
  //   //           '.',
  //   //           ',',
  //   //         )}&nbsp;&nbsp;<b className="fw-bold">Long:</b> ${lng
  //   //         .toString()
  //   //         .replace('.', ',')}`;
  //   //       return feature;
  //   //     }
  //   //   })
  //   //   .catch((error) => {
  //   //     console.log('error', error);
  //   //   });
  // };

  const addressByCoords = (lng: number, lat: number): string => {
    const feature = `Lat: ${lat.toString().replace('.', ',')} Long: ${lng
      .toString()
      .replace('.', ',')}`;
    return feature;
  };

  // ****************************  CONTROL INFOS  ***************************************/

  const getClientType = (): any => {
    return ClientTypeLabels[
      filtersContext?.clientType as keyof typeof ClientTypeLabels
    ];
  };

  const createStrategyInfo = (): Table => {
    const rows = [];
    if (filtersContext?.coordinates?.label !== undefined) {
      rows.push(
        new TableRow({
          children: [
            new TableCell({
              width: {
                size: 100,
                type: WidthType.PERCENTAGE,
              },
              children: [
                createInfoRequest(
                  t(`quote.details.generate_word.address`),
                  filtersContext?.coordinates.label,
                  false,
                ),
              ],
            }),
          ],
        }),
      );
    }
    rows.push(
      new TableRow({
        children: [
          new TableCell({
            width: {
              size: 100,
              type: WidthType.PERCENTAGE,
            },
            children: [
              createInfoRequest(
                t(`quote.details.generate_word.type_client`),
                getClientType(),
                false,
              ),
            ],
          }),
        ],
      }),
    );
    if (filtersContext?.power !== undefined) {
      rows.push(
        new TableRow({
          children: [
            new TableCell({
              width: {
                size: 100,
                type: WidthType.PERCENTAGE,
              },
              children: [
                createInfoRequest(
                  t(`quote.details.power`),
                  `${filtersContext?.power} MW`,
                  false,
                ),
              ],
            }),
          ],
        }),
      );
    }

    return new Table({
      width: {
        size: 100,
        type: WidthType.PERCENTAGE,
      },
      columnWidths: [100],
      borders,
      rows: [
        new TableRow({
          children: [
            new TableCell({
              width: {
                size: 100,
                type: WidthType.PERCENTAGE,
              },
              children: [
                new Table({
                  width: {
                    size: 100,
                    type: WidthType.PERCENTAGE,
                  },
                  borders,
                  columnWidths: [100],
                  rows,
                }),
              ],
            }),
          ],
        }),
      ],
    });
  };

  //* *********************** Display strategies ******************************* */
  const strategies =
    strategyContext?.data != null && strategyContext?.data.length > 0
      ? strategyContext?.data
      : [];

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const generateWordDocument = () => {
    const doc = new Document({
      compatibility: {
        version: 15,
        doNotExpandShiftReturn: true,
      },
      sections: [
        {
          properties: {
            titlePage: true,
          },
          footers: footerDoc,
          children: [
            headerDoc(t('quote.details.generate_word.title')),
            createBreak(0.6),
            createHeading(`${t('quote.details.generate_word.settings')}`),
            createStrategyInfo(),
            createBreak(0.4),
            createHeading(`${t('quote.details.generate_word.list_strategy')}`),
            /** ******** Details STARTEGYS ********* */
            new Table({
              width: {
                size: 100,
                type: WidthType.PERCENTAGE,
              },
              borders,
              columnWidths: [100],
              rows: [
                ...strategies
                  .filter((item) =>
                    strategyContext.selectedStrategies.includes(item.uid),
                  )
                  .map((strategy: IStrategy) => {
                    const arr: TableRow[] = [];

                    arr.push(
                      new TableRow({
                        children: [
                          new TableCell({
                            width: {
                              size: 100,
                              type: WidthType.PERCENTAGE,
                            },
                            children: [
                              createTitleText(
                                strategy,
                                t(`quote.details.generate_word.strategy`),
                              ),
                            ],
                          }),
                        ],
                      }),
                    );

                    if (strategy.distance?.minValue !== undefined) {
                      arr.push(
                        new TableRow({
                          children: [
                            new TableCell({
                              width: {
                                size: 100,
                                type: WidthType.PERCENTAGE,
                              },
                              children: [
                                createInfoRequest(
                                  `${t(
                                    'quote.details.generate_word.distance',
                                  )}`,
                                  getdistance(
                                    strategy?.distance.minValue,
                                    `${t('quote.details.generate_word.real')}`,
                                  ),
                                  true,
                                ),
                              ],
                            }),
                          ],
                        }),
                      );
                    }

                    if (strategy.distance?.maxValue !== undefined) {
                      arr.push(
                        new TableRow({
                          children: [
                            new TableCell({
                              width: {
                                size: 100,
                                type: WidthType.PERCENTAGE,
                              },
                              children: [
                                createInfoRequest(
                                  ``,
                                  getdistance(
                                    strategy?.distance.maxValue,
                                    `${t('quote.details.generate_word.maj')}`,
                                  ),
                                  true,
                                ),
                              ],
                            }),
                          ],
                        }),
                      );
                    }

                    if (strategy.cost?.minValue !== undefined) {
                      arr.push(
                        new TableRow({
                          children: [
                            new TableCell({
                              width: {
                                size: 100,
                                type: WidthType.PERCENTAGE,
                              },
                              children: [
                                createInfoRequest(
                                  `${t('quote.details.generate_word.cost')}`,
                                  getCostValue(
                                    strategy?.cost.minValue,
                                    `${t('quote.details.generate_word.min')}`,
                                  ),
                                  true,
                                ),
                              ],
                            }),
                          ],
                        }),
                      );
                    }

                    if (strategy.cost?.maxValue !== undefined) {
                      arr.push(
                        new TableRow({
                          children: [
                            new TableCell({
                              width: {
                                size: 100,
                                type: WidthType.PERCENTAGE,
                              },
                              children: [
                                createInfoRequest(
                                  ``,
                                  getCostValue(
                                    strategy?.cost.maxValue,
                                    `${t('quote.details.generate_word.max')}`,
                                  ),
                                  true,
                                ),
                              ],
                            }),
                          ],
                        }),
                      );
                    }

                    if (strategy.type !== undefined) {
                      arr.push(
                        new TableRow({
                          children: [
                            new TableCell({
                              width: {
                                size: 100,
                                type: WidthType.PERCENTAGE,
                              },
                              children: [
                                createInfoRequest(
                                  `${t([
                                    `quote.details.generate_word.strategy_type_labels.${strategy.type}`,
                                  ])}`,
                                  strategy.point.code,
                                  false,
                                ),
                              ],
                            }),
                          ],
                        }),
                      );
                    }

                    if (strategy.point.name !== undefined) {
                      arr.push(
                        new TableRow({
                          children: [
                            new TableCell({
                              width: {
                                size: 100,
                                type: WidthType.PERCENTAGE,
                              },
                              children: [
                                createInfoRequest(
                                  ``,
                                  strategy.point.name,
                                  false,
                                ),
                              ],
                            }),
                          ],
                        }),
                      );
                    }

                    if (
                      isCoordinate(
                        strategy?.pointCoords.lng,
                        strategy?.pointCoords.lat,
                      )
                    ) {
                      arr.push(
                        new TableRow({
                          children: [
                            new TableCell({
                              width: {
                                size: 100,
                                type: WidthType.PERCENTAGE,
                              },
                              children: [
                                createInfoRequest(
                                  `${t('quote.details.generate_word.point')}`,
                                  addressByCoords(
                                    strategy?.pointCoords.lng,
                                    strategy?.pointCoords.lat,
                                  ),
                                  false,
                                ),
                              ],
                            }),
                          ],
                        }),
                      );
                    }

                    if (strategy.point.details !== undefined) {
                      arr.push(
                        new TableRow({
                          children: [
                            new TableCell({
                              width: {
                                size: 100,
                                type: WidthType.PERCENTAGE,
                              },
                              children: [
                                createInfoRequest(
                                  `Site`,
                                  strategy.point.details,
                                  false,
                                ),
                              ],
                            }),
                          ],
                        }),
                      );
                    }

                    return arr;
                  })
                  .reduce((prev, curr) => prev.concat(curr), []),
              ],
            }),
          ],
        },
      ],
    });

    void Packer.toBlob(doc).then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `DOC.docx`;
      a.click();
      window.URL.revokeObjectURL(url);
    });
  };

  return (
    <StyledRow className="flex-wrap align-items-center footer">
      <Col className="blue-text-rak col-md-8 col-6 text-left px-2">{`${itemsLength} ${label}`}</Col>
      <Col className="col-md-4 col-6 text-end px-2 flex-item-2">
        <Button
          className="btn-rak-primary"
          disabled={selectedStrategies.length === 0}
          onClick={() => generateWordDocument()}
        >
          {`${t('footer.button')}`}
        </Button>
      </Col>
    </StyledRow>
  );
};

export default StrategyFooter;
